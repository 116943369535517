<template>
    <div class="c-upload-artwork">
        <file-dropper :uploadUrl="uploadUrl" @finished="appendUploaded" :isDirectUpload="true" ref="dropper"></file-dropper>
    </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import FileDropper from '@/components/utils/FileDropper'
import CONFIG from '@root/config'
import { transformProductAttributesToObject } from '@/utils/helpers'

export default {
    name: 'UploadArtwork',
    components: { FileDropper },
    props: ['isReplacingCPDF', 'templateData'],
    data () {
        return {
            isLoading: true,
            artworkUrls: null,
        }
    },
    methods: {
        ...mapActions('order', ['uploadArtwork', 'attachArtworkToOrderLine', 'fetchOrder', 'fetchOrderArtworkStatus', 'setOrderLine']),
        checkArtworkType (artworks) {
            const refinedArtwork = {
                artworks: [],
            }
            artworks.forEach((artwork) => {
                if (artwork.fileName.includes(`${this.orderLine.orderLineNumber}_` && artwork.fileName.includes('.pdf'))) {
                    refinedArtwork.cpdf = [artwork]
                } else if (artwork.fileName.includes(`${this.orderLine.orderLineNumber}_proof`) && artwork.fileName.includes('.pdf')) {
                    refinedArtwork.proof = [artwork]
                } else {
                    refinedArtwork.artworks.push(artwork)
                }
            })

            if (!refinedArtwork.artworks.length) {
                delete refinedArtwork.artworks
            }

            return refinedArtwork
        },
        attachArtworkAction (artworkUrls) {
            const payload = {
                orderLineId: this.orderLine.orderLineId,
                artworkUrls: artworkUrls,
                orderLineNumber: this.orderLine.orderLineNumber,
                sku: this.orderLine.product.sku,
                accountName: `${this.user.firstName} ${this.user.lastName}`,
            }
            if (artworkUrls[0].fileName.includes(`${this.orderLine.orderLineNumber}_`) && artworkUrls[0].fileName.includes('.pdf')) {
                if (this.templateData && this.templateData.length) {
                    payload.cutcontourCheck = this.templateData[0].cutcontourCheck
                    payload.pagecount = this.templateData[0].pagecount
                }
                payload.attributes = transformProductAttributesToObject(this.orderLine.product.attributes)
            }

            if (this.isReplacingCPDF) {
                payload.artworkStatus = 'ATTENTION'
                const previousCPDF = this.artworks.find((e) => e.artworkType === 'CPDF' && e.artworkStatus === 'ARCHIVED' && e.checkedBy && Array.isArray(e.checkedBy) && e.checkedBy.length)
                payload.checkedBy = previousCPDF.checkedBy
            }
            return this.attachArtworkToOrderLine({
                orderId: this.$route.params.orderId,
                accountId: this.$route.params.accountId,
                payload,
            })
        },
        appendUploaded ($event) {
            this.artworkUrls = $event.data.data
            this.isLoading = false
            const refinedArtworks = this.checkArtworkType(this.artworkUrls)
            const promises = []
            Object.values(refinedArtworks).forEach((artwork) => {
                promises.push(this.attachArtworkAction(artwork))
            })

            Promise.all(promises).then((values) => {
                this.fetchOrder({
                    orderId: this.$route.params.orderId,
                    currentOrderline: this.$route.params.orderLineId,
                })
                this.fetchOrderArtworkStatus({
                    orderId: this.$route.params.orderId,
                })
                this.$refs.dropper.resetFiles()
                this.artworkUrls = null
                this.$emit('update:isReplacingCPDF', false)

                const orderPayments = this.order.payments || []
                const orderLineOrderPayments = orderPayments[0] || []
                const segmentLoad = {
                    userId: this.$route.params.accountId,
                    type: 'ARTWORK', // Type of artwork check being done // data.data.artwork.artworkType
                    cartId: this.order.cartId, // Unique identifier of the cart the user actually validated
                    orderId: this.order.orderId, // Unique identifier of the order
                    payment: orderLineOrderPayments['paymentMethod'] || '', // Payment method used for this specific order
                    product_tid: this.orderLine.product.productTid || '', // Unique identifier of the product sold
                    orderStateId: this.order.orderStatus,
                    orderdetailId: this.orderLine.orderLineId,
                }
                this.$segmentClient.sendAction(this, 'Processed Artwork', segmentLoad)

                this.$root.$emit('notification:global', {
                    message: 'Artwork was attached',
                })
            })
        },
    },
    computed: {
        ...mapState('order', ['order', 'orderLine']),
        ...mapState('auth', ['user']),
        ...mapGetters('order', ['artworks']),
        uploadUrl () {
            if (!Object.keys(this.order).length) {
                return
            }

            return CONFIG.API.ROUTES.ARTWORKS.UPLOAD
                .replace('{customerId}', this.$route.params.accountId)
                .replace('{orderId}', this.$route.params.orderId)
                .replace('{orderLineId}', this.$route.params.orderLineId)
        },
    },
}
</script>

<style lang="scss" scoped>
    .c-upload-artwork {
        &__upload-box {
            background-color: #F9F9F9;
            border: 1px dashed #979797;
            -webkit-border-radius: 5px;
            -moz-border-radius: 5px;
            border-radius: 5px;
            padding: 30px 40px;
            display: flex;
            align-items: center;
            margin-bottom: 30px;
            margin-top: 10px;
            cursor: pointer;
            min-height: 100px;
        }

        &__upload-icon {
            font-size: 50px !important;
            line-height: 45px;
        }

        &__note {
            color: #666666;
            font-size: 14px;
            line-height: 19px;
            margin: 0;
            margin-left: 30px;

            span {
                font-weight: normal;
                font-size: 12px;
            }
        }
    }
</style>
