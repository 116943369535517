<template>
    <div class="c-artwork-files">
        <h3 class="font-weight-normal mt-2">Artwork files</h3>
        <div class="d-flex mb-3">
            <mercur-button data-e2e="orderlineViewDownloadAttachmentsButton" class="btn btn-blue flex-grow-1 text-uppercase font-weight-bold" :disabled="isDownloadingAttachments || !Object.keys(artworks).length" @click.native="downloadAllAttachments"><i class="fas fa-download"></i>Attachments</mercur-button>
            <mercur-button class="btn btn-blue text-uppercase font-weight-bold" @click.native="getTemplates" :disabled="!templateData"><i class="fas fa-download"></i>Template</mercur-button>
        </div>
        <div class="c-artwork-files__list border" :class="isScrollable">
            <div class="c-artwork-files__list-body" v-if="artworks">
                <div class="c-artwork-files__list-box" :class="{ 'c-artwork-files__list-box--rejected': checkArtworkStatus(artwork.artworkStatus) }" v-for="(artwork, key) in artworks" :key="key">
                    <div class="c-artwork-files__list-item" v-for="(item, index) in artwork.artworkUrls" :key="index">
                        <div class="c-artwork-files__list-item-box">
                            <p class="c-artwork-files__list-item-file" v-if="item.pdf || item.png || item.mockup">
                                <span @click="getOrderLineArtwork"><strong>Files bundle</strong></span>
                            </p>
                            <p v-else class="c-artwork-files__list-item-file"><span @click="getDownloadUrl(item)">
                                <template v-if="typeof item === 'string'">
                                    <strong>{{ item | artworkName }}</strong>
                                </template>
                                <template v-else>
                                    <strong>{{ item.fileName | prettyName(orderLine.orderLineNumber) }}</strong>
                                </template>
                            </span>
                            <span class="c-artwork-files__list-item-tag" v-if="['CPDF', 'PROOF'].includes(artwork.artworkType)">{{ artwork.artworkType }}</span></p>
                            <p class="c-artwork-files__list-item-date">{{ artwork.dateCreated }} ({{artwork.accountName !== '' ? artwork.accountName : 'customer'}})</p>
                        </div>
                        <mercur-button v-if="canArchiveArtwork(artwork, index) && hasPermission('archiveArtwork')"
                                   class="btn btn-icon"
                                   @click.native="archive(artwork)" :disabled="isArchiving"
                        ><i class="fas fa-trash"></i></mercur-button>
                    </div>
                </div>
            </div>
        </div>
        <div class="c-artwork-files__buttons d-flex align-items-center mt-2">
            <div class="flex-grow-1">
                <mercur-checkbox v-model="isReplacingCPDF" id="replacing">Replacing 1st cPDF</mercur-checkbox>
            </div>
            <div>
                <mercur-button class="btn btn-raised" @click.native="deleteAll" :disabled="areArtworksArchived || isArchiving || immutableStatuses.includes(orderLine.orderLineStatus)">Delete all files</mercur-button>
            </div>
        </div>
        <upload-artwork v-if="!immutableStatuses.includes(orderLine.orderLineStatus) && orderLine.orderLineStatus !== 'CANCELLED' && hasPermission('attachArtworkToOrderLine')" :isReplacingCPDF.sync="isReplacingCPDF" :templateData="templateData"></upload-artwork>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import UploadArtwork from '@/components/elements/order/UploadArtwork'
import CONFIG from '@root/config'

export default {
    name: 'ArtworkFiles',
    components: { UploadArtwork },
    props: {
        templateData: {
            default: null,
        },
    },
    data () {
        return {
            isArchiving: false,
            isDownloadingAttachments: false,
            collectionArchived: false,
            immutableStatuses: CONFIG.ORDERS.IMMUTABLE_STATUSES,
            invalidDownloadStatus: ['ARCHIVED', 'DECLINED'],
            isReplacingCPDF: false,
        }
    },
    computed: {
        ...mapState('order', ['order', 'orderLine']),
        ...mapGetters('order', ['artworks']),
        areArtworksArchived () {
            return this.artworks.every(artwork => artwork.artworkStatus === 'ARCHIVED')
        },
        isScrollable () {
            if (!this.artworks || !this.artworks.length) {
                return 'u-no-scroll'
            }
            const arr = this.artworks.flatMap((e) => e.artworkUrls)
            if (arr.length <= 3) {
                return 'u-no-scroll'
            }
            return true
        },
    },
    methods: {
        ...mapActions('order', ['archiveArtwork', 'updateArtworkStatus', 'archiveAllArtworks']),
        archive (artwork) {
            this.isArchiving = true
            this.archiveArtwork({
                accountId: this.$route.params.accountId,
                orderId: this.order.orderId,
                payload: artwork,
            }).then(() => {
                this.isArchiving = false
                this.$root.$emit('notification:global', {
                    message: 'Artwork was archived',
                })
            })
        },
        canArchiveArtwork (item, index) {
            return index === 0 && !this.checkArtworkStatus(item.artworkStatus) && !this.immutableStatuses.includes(this.orderLine.orderLineStatus) && this.orderLine.orderLineStatus !== 'CANCELLED'
        },
        checkArtworkStatus (status) {
            return status === 'REJECTED' || status === 'ARCHIVED' || status === 'DECLINED'
        },
        downloadAllAttachments () {
            let isLayered = false
            this.artworks.forEach((artwork) => {
                if (artwork.artworkUrls.some((item) => {
                    return item.pdf || item.png || item.mockup
                })) {
                    isLayered = true
                }
            })

            if (isLayered) {
                this.getOrderLineArtwork()
                return
            }

            const url = CONFIG.API.ROUTES.ARTWORKS.DOWNLOAD_ALL.replace('{orderLineId}', this.orderLine.orderLineId)
            const reversedArtworkIds = Object.keys(this.$store.state.order.orderLine.artwork).reverse()
            let payload = []

            for (const artworkId of reversedArtworkIds) {
                const artwork = this.$store.state.order.orderLine.artwork[artworkId]
                if (!this.invalidDownloadStatus.includes(artwork.artworkStatus)) {
                    payload = payload.concat(artwork.artworkUrls)
                }
            }

            if (!payload.length) {
                this.$root.$emit('notification:global', {
                    message: 'No files to download',
                })
                return
            }

            this.addJob(url)
            this.isDownloadingAttachments = true

            this.$api.post(url, { fileUrls: payload }, { responseType: 'blob' }).then(({ data }) => {
                const blob = new Blob([data], { type: 'application/x-zip' })
                const link = document.createElement('a')
                link.href = window.URL.createObjectURL(blob)
                link.download = `${this.orderLine.orderLineId}.zip`
                link.click()
            }).catch(err => {
                if (CONFIG.DEBUG) {
                    console.log(err)
                }
            }).finally(() => {
                this.finishJob(url)
                this.isDownloadingAttachments = false
            })
        },
        getTemplates () {
            if (!this.templateData.length) {
                this.$root.$emit('notification:global', {
                    message: 'No template for this SKU',
                    type: 'error',
                })
                return
            }

            const downloadUrl = this.templateData.find((e) => e.manualTemplateLink && e.manualTemplateLink !== '').manualTemplateLink
            window.open(downloadUrl, '_blank')
        },
        deleteAll () {
            this.isArchiving = true
            const payload = { artworks: this.artworks }

            this.archiveAllArtworks({
                accountId: this.$route.params.accountId,
                orderId: this.order.orderId,
                payload: payload,
            }).then(() => {
                this.isArchiving = false
                this.$root.$emit('notification:global', {
                    message: 'Artwork was archived',
                })
            })
        },
        getDownloadUrl (name) {
            if (typeof name === 'string' && (name.includes('amazonaws') || name.includes('drukzo'))) {
                window.open(name, '_blank')
                return
            }
            const url = CONFIG.API.ROUTES.ARTWORKS.GET_DOWNLOAD_URL

            let objectName = ''
            let fileName = null
            if (typeof name === 'string') {
                objectName = name.substring(name.indexOf(this.$route.params.applicationId))
            } else {
                objectName = name.objectName
                if (name.fileName.includes(`${this.orderLine.orderLineNumber}_`)) {
                    fileName = this.$options.filters.prettyName(name.fileName, this.orderLine.orderLineNumber)
                }
            }

            if (!objectName.includes(this.$route.params.applicationId)) {
                this.$root.$emit('notification:global', {
                    message: 'Artwork does not belong to this shop',
                })
                return
            }

            this.$root.$emit('notification:global', {
                message: 'Downloading file...',
            })
            const payload = { objectName }
            if (fileName) {
                payload.fileName = fileName
            }
            this.post(url, payload).then((data) => {
                window.open(data.url, '_blank')
            })
        },
        getOrderLineArtwork () {
            const url = CONFIG.API.ROUTES.ARTWORKS.GET_ORDERLINE_ARTWORK.replace('{orderLineId}', this.$route.params.orderLineId)
            this.get(url).then(({ url }) => {
                window.open(url, '_blank')
            })
        },
    },
    filters: {
        artworkName: function (name) {
            if (!name) {
                return
            }
            const fileName = name.split('/').reverse()[0]
            const ext = fileName.split('.').reverse().shift()
            return fileName.split('_')[0] + '.' + ext
        },
        prettyName: function (name, orderLineNumber) {
            if (!name && !this.orderLine) {
                return
            }
            if (name.includes(`${orderLineNumber}_proof`)) {
                return `${orderLineNumber}_proof.pdf`
            }
            if (name.includes(`${orderLineNumber}_`)) {
                return `${orderLineNumber}.pdf`
            }
            return name
        },
    },
}
</script>

<style lang="scss" scoped>
    $blue: #3790DE;
    $light-grey: #d6d6d6;
    .c-artwork-files {
        h2 {
            margin-top: 5px;
        }
        &__buttons-wrapper {
            display: flex;
            flex-direction: row;
        }
        &__btn:first-child {
            flex: 1;
            margin-right: 15px;
        }
        &__btn {
            background-color: $blue !important;
            color: white;
            text-transform: uppercase;
            font-weight: bold;
            -webkit-border-radius: 0;
            -moz-border-radius: 0;
            border-radius: 0;
            padding: 0;
            margin-bottom: 10px;

            &[disabled] {
                background-color: #DDD !important;
            }
        }
        &__list {
            min-height: 100px;
            max-height: 200px;
            overflow-y: scroll;

            &-box {
                border-bottom: 1px solid $light-grey;

                &:nth-last-child(1) {
                    border: none;
                }

                &--rejected {
                    background: #BEBEBE;
                }

            }

            &-item {
                font-size: 12px;
                padding: 10px;
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;

                p {
                    margin: 0;
                    line-height: 20px;
                    position: relative;
                }

                &-tag {
                    background-color: #B7B7B7;
                    color: #666666;
                    padding: 1px 5px;
                    margin-left: 5px;
                    border-radius: 3px;
                }

                &-date {
                    font-size: 11px;
                    margin-bottom: 0;
                }

                &-box {
                    flex: 1;
                }

                &-delete {
                    cursor: pointer;
                    opacity: .8;
                    min-width: unset;
                    transition: opacity .2s ease;
                    padding: 0 !important;
                    height: 40px;
                    border-radius: 50%;
                    margin: auto;

                    &:hover {
                        opacity: 1;
                    }
                }

                &-file {
                    span {
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        max-width: 200px;
                        display: inline-block;
                        text-decoration: underline;
                        cursor: pointer;
                    }
                }
            }
        }
        &__delete-all {
            text-transform: uppercase;
            margin-top: 10px;

            &:hover {
                text-decoration: underline;

                &:before {
                    display: none;
                }
            }
        }
    }

</style>
