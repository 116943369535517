<template>
    <div class="c-previous-artwork">
        <dropdown-card title="Previous Production Artwork">
            <div class="c-previous-artwork__wrapper">
                <div class="c-previous-artwork__item" v-for="(artwork, index) in items" :key="index">
                    <div class="c-previous-artwork__item-box">
                        <i class="fas fa-paint-brush"></i>
                    </div>
                    <p class="c-previous-artwork__item-name">
                        <strong>
                            <template v-if="typeof artwork === 'string'">
                                <strong>{{ artwork | artworkName }}</strong>
                            </template>
                            <template v-else-if="artwork.artworkUrls && artwork.artworkUrls.length">
                                <strong v-for="item in artwork.artworkUrls" :key="item.fileName">
                                    {{ item.fileName }}
                                </strong>
                            </template>
                            <template v-else>
                                <strong>{{ artwork.fileName }}</strong>
                            </template>
                        </strong>
                    </p>
                </div>
            </div>
        </dropdown-card>
    </div>
</template>

<script>
import CONFIG from '@root/config'
import ResourcesView from '@/views/ResourcesView'
import DropdownCard from '@/components/elements/DropdownCard'

export default {
    name: 'PreviousArtwork',
    mixins: [ResourcesView],
    components: { DropdownCard },
    data () {
        return {
            isNotApplicationBound: true,
        }
    },
    filters: {
        artworkName: function (name) {
            return name.split('/').reverse()[0]
        },
    },
    created () {
        this.url = CONFIG.API.ROUTES.ARTWORKS.PREVIOUS_ARTWORK.replace('{accountId}', this.$store.state.order.order.buyer.accountId)
    },
}
</script>

<style lang="scss">
    $light-grey: #d6d6d6;
    .c-previous-artwork {
        h2 {
            margin-top: 5px;
        }
        &__wrapper {
            overflow-y: scroll;
            height: 285px;
            border: 1px solid $light-grey;
        }
        &__item {
            padding: 10px;
            border-bottom: 1px solid $light-grey;
            display: flex;
            flex-direction: row;

            i {
                font-size: 24px;
            }

            &-box {
                border: 1px solid $light-grey;
                padding: 15px;
                height: 70px;
                width: 70px;
                display: flex;
                margin-right: 15px;
            }

            &-name {
                margin-top: 0;
                font-size: 13px;
                line-height: 18px;
                word-break: break-all;
                flex: 1;
            }
        }
    }
</style>
