<template>
    <div class="c-product-info" v-if="Object.keys(orderLine).length">
        <h2>
            <template v-if="templateData && templateData.length">{{ templateData[0].internalName }}</template>
            <span>Platform name: {{ orderLine.product.productName }}</span>
            <span class="icon text-yellow" @click="copyOrderLineToClipboard"><i class="fas fa-star"></i></span>
        </h2>
        <table class="c-product-info__table">
            <tbody>
            <tr class="c-product-info__slas">
                <td colspan="4">
                    <div class="c-product-info__sla-box">
                        <span class="c-product-info__sla">
                            <span
                                class="c-product-info__sla-tag"
                                v-if="orderQueueStatus && orderLineArtworkStatus"
                                :class="getSlaColor(orderLineArtworkStatus.customerSla)"
                            >{{ orderLineArtworkStatus.customerSla }}</span>
                            <span v-else>Not available</span>
                            Customer SLA
                        </span>
                        <span class="c-product-info__sla">
                            Supplier SLA
                            <span
                                v-if="orderQueueStatus && orderLineArtworkStatus"
                                class="c-product-info__sla-tag"
                                :class="getSlaColor(orderLineArtworkStatus.supplierSla)"
                            >{{ orderLineArtworkStatus.supplierSla }}</span>
                            <span v-else>Not available</span>
                        </span>
                    </div>
                </td>
            </tr>
            </tbody>
            <tbody class="c-product-info__section">
            <tr>
                <td>EXPORT</td>
                <td colspan="3" v-if="exportSettings">{{ exportSettings }}</td>
            </tr>
            </tbody>
            <tbody class="c-product-info__section">
            <tr v-for="(item, index) in productAttributes" :key="index">
                <td>
                    <div
                        v-for="(attributeName, attributeNameKey) in item.attributeName.toString().split('#')"
                        :key="attributeNameKey"
                    >
                        {{ attributeName }}
                    </div>
                </td>
                <td class="text-break" colspan="3">
                    <div
                        v-for="(attributeOption, attributeOptionKey) in item.attributeOption.toString().split('#')"
                        :key="attributeOptionKey"
                    >
                        {{ attributeOption }}
                    </div>
                </td>
            </tr>
            <tr>
                <td>Quantity:</td>
                <td>{{ orderLine.product.quantity }}</td>
            </tr>
            </tbody>
            <tbody class="c-product-info__section">
            <tr>
                <td>SKU:</td>
                <td class="text-break" colspan="3"><a :href="productUrl" target="_blank">{{ orderLine.product.sku }}</a></td>
            </tr>
            </tbody>
            <tbody class="c-product-info__section">
            <tr>
                <td>Supplier:</td>
                <td colspan="3" v-if="supplierData[orderLine.orderLineId]">{{ supplierData[orderLine.orderLineId].supplierName }}
                </td>
            </tr>
            </tbody>
        </table>
        <br>
        <hr>
        <div class="c-product-info__checkboxes">
            <div>
                <mercur-checkbox v-model="orderLine.noProofRequested" disabled id="noproof">No proof requested</mercur-checkbox>
            </div>
            <div>
                <mercur-checkbox id="reprint" disabled>Reprint complaint</mercur-checkbox>
            </div>
            <div v-if="orderLine.productPrice + orderLine.shippingPrice > 400">
                <mercur-checkbox v-model="hasFirstCheck" disabled id="firstcheck">1st Artwork check done</mercur-checkbox>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'
import { copyToClipboard } from '@/utils/helpers'

export default {
    name: 'ProductInfo',
    props: {
        templateData: {
            default: null,
        },
    },
    computed: {
        ...mapState('order', ['order', 'orderLine', 'orderQueueStatus', 'supplierData']),
        ...mapGetters('order', ['artworks']),
        productUrl () {
            if (this.orderLine.product.additionalProductInfo.editLink && this.selectedShop.configuration.shopinfo) {
                return 'https://' + this.selectedShop.configuration.shopinfo.domain + '/p/' + this.orderLine.product.additionalProductInfo.editLink.split('/')[0]
            }

            return '#'
        },

        orderLineArtworkStatus () {
            return this.$store.getters['order/orderLineQueueStatus'](this.orderLine.orderLineId)
        },

        productAttributes: function () {
            let arr = []
            let productAttributes = []
            const DIMENSIONS = ['size_format', 'size_width', 'size_length', 'size_diameter', 'size', 'width', 'length']

            if (Array.isArray(this.orderLine.product.attributes)) {
                arr = this.orderLine.product.attributes
            } else {
                Object.entries(this.orderLine.product.attributes).forEach((item) => {
                    arr.push({
                        attributeName: item[0],
                        attributeOption: item[1],
                    })
                })
            }

            arr.forEach((item) => {
                if (item.attributeOption.toString().toLowerCase() !== 'no') {
                    productAttributes.push(item)
                }

                if (DIMENSIONS.includes(item.attributeName)) {
                    productAttributes.splice(productAttributes.findIndex((e) => e.attributeName === item.attributeName), 1)
                    productAttributes.unshift(item)
                }
            })

            return productAttributes
        },

        exportSettings () {
            if (!this.templateData || !this.templateData.length) {
                return 'Not available'
            }

            const item = this.templateData[0]

            if (item.cropmarks === true && item.colorProfile === 'ISOcoated_v2_300_eci.icc') {
                return 'STANDARD'
            }

            if (item.cropmarks === false && item.bleed !== 0) {
                return 'EXCEPTIONAL'
            }

            if (item.bleed === 0 && item.colorProfile === 'ISOcoated_v2_300_eci.icc') {
                return 'PEN&TEXTILES'
            }

            if (item.layered === true) {
                return 'LAYERED'
            }

            if (item.colorProfile === 'ISOnewspaper26v4.icc') {
                return 'NEWSPAPER'
            }

            if (item.bleed === 1) {
                return 'FLYERALARM'
            }

            return 'not available!'
        },

        hasFirstCheck: {
            get () {
                return this.artworks.some(artwork => artwork.artworkStatus !== 'ARCHIVED' && artwork.artworkType === 'CPDF')
            },
        },
    },
    methods: {
        copyOrderLineToClipboard () {
            copyToClipboard(this.orderLine.orderLineNumber)
            this.$root.$emit('notification:global', {
                message: 'Orderline Number copied to clipboard',
            })
        },

        getSlaColor (color) {
            const className = 'c-product-info__sla-tag'
            const SLA_TIME = 0.45 // 45 minutes
            const value = parseFloat(color.replace(':', '.'))
            if (value < 0) {
                return `${className}--red`
            } else if (value < SLA_TIME) {

            } else {
                return `${className}--green`
            }
        },
    },
}
</script>

<style lang="scss" scoped>
    $dark-grey: #666666;
    $light-grey: #CBCBCB;
    $green: #77BB22;

    h2 {
        font-size: 22px;
        margin-top: 5px;
        position: relative;
        padding-right: 22px;

        &:after {
            display: none;
        }

        span {
            font-size: 1rem;
            font-weight: normal;
        }

        span.icon {
            cursor: pointer;
            transition: all .2s ease;
            position: absolute;
            right: 0;
            top: 0;
            font-size: 20px;

            &:hover {
                transform: rotateZ(90deg) scale(1.1);
                filter: brightness(90%);
            }
        }
    }

    .c-product-info {
        &__checkboxes {
            margin-top: 10px;
        }

        &__table {
            border-collapse: collapse;
        }

        &__sla-box {
            display: flex;
            justify-content: space-between;
        }

        &__sla-item {
            flex: 1;
            margin: 0;
        }

        &__sla-tag {
            padding: 7px 10px;
            background: #F1B818;
            color: white;
            font-size: 16px;
            font-weight: bold;

            &--red {
                background: #DA0724;
            }

            &--green {
                background: #77BE29;
            }
        }
        &__sla {
            font-size: 11px;
            font-weight: normal;
            color: #777777;
        }
        &__slas {
            td:first-child {
                border-top: 1px solid #d6d6d6;
                border-bottom: 1px solid #d6d6d6;
                padding: 15px 0;
            }
        }

        &__section {
            td:first-child {
                font-weight: bold;
                padding-right: 15px;
                white-space: nowrap;
            }

            tr:first-child td {
                padding-top: 15px;
            }

            i {
                margin-left: 5px;
            }
        }
    }
</style>
