<template>
    <div class="c-followup">
        <h3 class="font-weight-normal mt-2">Follow up</h3>
        <template v-if="orderLine.orderLineStatus === 'CANCELLED'">
            Orderline is cancelled
        </template>
        <template v-else>
            <template v-if="!immutableStatuses.includes(orderLine.orderLineStatus)">
                <mercur-button class="btn btn-primary w-100 mb-3" @click.native="setForProduction" v-if="orderLine.noProofRequested" :disabled="loading || !cpdfArtwork && !orderLine.product.stockItem">
                    <span v-if="!cpdfArtwork && !orderLine.product.stockItem">PRODUCTION FILE IS MISSING</span>
                    <span v-else>ARTWORK READY FOR PRODUCTION</span>
                </mercur-button>
                <div v-if="followUps">
                    <pretty-select
                        data-e2e="orderlineViewFollowupMessageSelect"
                        class="mb-4"
                        label="label"
                        placeholder="Select message"
                        :options="followUps"
                        v-model="selectedFollowups"
                        multiple
                    ></pretty-select>
                </div>
                <form @submit.prevent="submitForm">
                    <mercur-textarea class="mb-3" v-model="form.followup.message" :class="getValidationClass($v, ['followup', 'message'])">
                        Followup message
                        <template #note>
                            <span class="form-error" v-if="!$v.form.followup.message.required">Followup message is required</span>
                        </template>
                    </mercur-textarea>
                    <mercur-button data-e2e="orderlineViewFollowupMessageSubmitButton" class="btn btn-primary w-100 text-uppercase" v-if="hasPermission('addArtworkFollowup')" type="submit" :disabled="loading || proofIsAvailable || !form.followup.message">{{ submitText }}</mercur-button>
                </form>
            </template>
            <div v-else>
                <p>Orderline is in production.</p>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex'
import FormMixin from '@/mixins/Form'
import CONFIG from '@root/config'
import { required } from 'vuelidate/lib/validators'
import PrettySelect from '@/components/utils/PrettySelect'

export default {
    name: 'FollowUp',
    mixins: [FormMixin],
    components: { PrettySelect },
    props: {
        hasShopNoProofRequest: {
            default: false,
        },
    },
    data () {
        return {
            form: {
                followup: {},
            },
            selectedFollowups: null,
            immutableStatuses: CONFIG.ORDERS.IMMUTABLE_STATUSES,
        }
    },
    validations: {
        form: {
            followup: {
                message: {
                    required,
                },
            },
        },
    },
    watch: {
        selectedFollowups: {
            deep: true,
            handler (value) {
                if (!value || !value[0]) {
                    return
                }

                const messages = value.map(e => e.message)
                this.form.followup.message = messages.join('\n')
                this.form.followup.title = value[0].label

                const values = value.map(e => e.artworkFollowupAction)

                if (values.includes('REQUEST CS')) {
                    this.$set(this.form.followup, 'artworkFollowupAction', 'REQUEST CS')
                } else if (values.includes('DECLINE')) {
                    this.$set(this.form.followup, 'artworkFollowupAction', 'DECLINE')
                } else {
                    this.$set(this.form.followup, 'artworkFollowupAction', 'SEND PROOF')
                }
            },
        },
    },
    computed: {
        ...mapState('order', ['followUps', 'orderLine', 'order']),
        ...mapGetters('order', ['orderLineHasCpdf']),
        submitText () {
            switch (this.form.followup.artworkFollowupAction) {
            case 'DECLINE':
                return 'Send decline to customer'
            case 'SEND PROOF':
                return 'Send proof'
            case 'REQUEST CS':
                return 'Send request to CS'
            default:
                return 'Select message'
            }
        },
        proofIsAvailable () {
            if (this.form.followup.artworkFollowupAction === 'SEND PROOF' && !this.orderLineHasCpdf) {
                return true
            }
            return false
        },
        cpdfArtwork () {
            if (!this.orderLine.artwork || !Object.keys(this.orderLine.artwork).length) {
                return null
            }

            if (Object.values(this.orderLine.artwork).find((item) => item.artworkType === 'CPDF' && ['OPEN', 'PROOF', 'APPROVED', 'MANUAL_CHECK'].includes(item.artworkStatus))) {
                return Object.values(this.orderLine.artwork).find((item) => item.artworkType === 'CPDF' && ['OPEN', 'PROOF', 'APPROVED', 'MANUAL_CHECK'].includes(item.artworkStatus))
            }

            return null
        },
        action () {
            return CONFIG.API.ROUTES.ORDERS.FOLLOWUP.SUBMIT
                .replace('{accountId}', this.$store.state.auth.user.accountId)
                .replace('{customerId}', this.$route.params.accountId)
                .replace('{orderId}', this.order.orderId)
                .replace('{orderLineId}', this.orderLine.orderLineId)
        },
    },
    methods: {
        ...mapActions('order', ['fetchFollowups', 'processFollowup', 'fetchOrder', 'fetchOrderArtworkStatus', 'approveArtwork', 'setOrderLineStatus']),
        setForProduction () {
            this.loading = true
            if (this.orderLine.product.stockItem) {
                const url = CONFIG.API.ROUTES.ORDERS.ORDERLINE.SET_STATUS
                    .replace('{accountId}', this.$route.params.accountId)
                    .replace('{orderId}', this.$route.params.orderId)
                    .replace('{orderLineId}', this.$route.params.orderLineId)

                if (this.orderLine.orderLineStatus !== 'IN_PROGRESS') {
                    this.setOrderLineStatus({
                        url: url,
                        status: 'IN_PROGRESS',
                    }).then(() => {
                        this.setOrderLineStatus({
                            url: url,
                            status: 'READY_FOR_PRODUCTION',
                            orderId: this.$route.params.orderId,
                        }).catch(({ data }) => {
                            this.$root.$emit('notification:global', {
                                message: data.data[0].error,
                            })
                        }).finally(() => {
                            this.loading = false
                        })
                    })

                    return
                }

                this.setOrderLineStatus({
                    url: url,
                    status: 'READY_FOR_PRODUCTION',
                    orderId: this.$route.params.orderId,
                }).catch(({ data }) => {
                    this.$root.$emit('notification:global', {
                        message: data.data[0].error,
                    })
                }).finally(() => {
                    this.loading = false
                })

                return
            }

            this.approveArtwork({
                orderId: this.order.orderId,
                accountId: this.$route.params.accountId,
                payload: this.cpdfArtwork,
            }).then(() => {
                this.$root.$emit('notification:global', {
                    message: 'Artwork was set ready for production',
                    type: 'success',
                })
                this.fetchOrder({
                    orderId: this.$route.params.orderId,
                    currentOrderline: this.$route.params.orderLineId,
                }).finally(() => {
                    this.loading = false
                })
            }).catch(({ data }) => {
                this.$root.$emit('notification:global', {
                    message: 'Something went wrong',
                    type: 'error',
                })
                this.loading = false
            })
        },
        submitForm () {
            const payload = this.form.followup
            payload.amount = parseFloat(this.orderLine.orderLineTotals.productsPrice) + parseFloat(this.orderLine.orderLineTotals.shippingPrice)
            this.submit(this.action, payload).then(({ data }) => {
                this.$set(this, 'form', { followup: {} })
                this.$set(this, 'selectedFollowups', null)
                this.fetchOrder({
                    orderId: this.$route.params.orderId,
                    currentOrderline: this.$route.params.orderLineId,
                })
                this.fetchOrderArtworkStatus({
                    orderId: this.order.orderId,
                })
                this.$root.$emit('notification:global', {
                    message: 'Followup was submitted',
                })
            })
        },
    },
    created () {
        if (!this.hasPermission('listArtworkFollowups')) {
            return
        }
        this.fetchFollowups()
    },
}
</script>
