<template>
    <mercur-card class="dropdown-card" :class="{'dropdown-card--limit-height': !isOpen}">
        <h3 class="font-weight-normal" @click="isOpen = !isOpen">{{ title }}
            <span class="dropdown-card__icon-down">
                 <i class="fas" :class="isOpen ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </span>
        </h3>
        <div class="dropdown-card__content" v-show="isOpen">
            <slot></slot>
        </div>
    </mercur-card>
</template>

<script>

export default {
    name: 'DropdownCard',
    props: {
        title: {
            default: '',
        },
    },
    data () {
        return {
            isOpen: false,
        }
    },
}
</script>

<style lang="scss" scoped>
    .dropdown-card {
        &--limit-height {
            max-height: 58px;
        }

        h3 {
            cursor: pointer;
            margin: 0;
        }

        &__icon-down {
            float: right;
        }

        &__content {
            margin-top: 16px;
        }
    }
</style>
