<template>
    <div>
        <p class="checked-in-message" v-if="isAssigned && isUserOrder">Complete order is checked in by you</p>
        <div class="row">
            <div class="col-sm-8 mb-4">
                <div class="row">
                    <div class="col-6">
                        <p v-if="isOverWarningValue" class="value-note">The value of this order is above € {{ warningValue }},-</p>
                        <mercur-card>
                            <product-info :templateData="templateData"></product-info>
                        </mercur-card>
                    </div>
                    <div class="col-6" v-if="isCheckInProgress">
                        <div class="lock-screen" v-if="isCheckInProgress">
                            <mercur-card class="text-center">
                                <i class="fas fa-exclamation-circle"></i>
                                <h4>Artwork check in progress by:</h4>
                                <h3>{{ activeDtpAgent.accountName }}</h3>
                            </mercur-card>
                        </div>
                    </div>
                    <div class="col-6" v-else>
                        <mercur-card class="c-card max-height-600 mb-4">
                            <artwork-files :templateData="templateData"></artwork-files>
                        </mercur-card>
                        <previous-artwork></previous-artwork>
                    </div>
                </div>
            </div>
            <div class="col-sm-4">
                <dtp-status></dtp-status>
                <mercur-card class="mb-4">
                    <follow-up></follow-up>
                </mercur-card>
                <dropdown-card class="mb-4" title="Notes">
                    <log-form></log-form>
                </dropdown-card>
                <dropdown-card title="Log">
                    <log-list :keywords="['artwork', 'no proof']"></log-list>
                </dropdown-card>
            </div>
        </div>
    </div>
</template>

<script>
import ProductInfo from '@/components/elements/order/ProductInfo'
import ArtworkFiles from '@/components/elements/order/ArtworkFiles'
import PreviousArtwork from '@/components/elements/order/PreviousArtwork'
import LogList from '@/components/elements/order/LogList'
import FollowUp from '@/components/elements/order/FollowUp'
import LogForm from '@/components/elements/order/LogForm'
import DtpStatus from '@/components/elements/order/DtpStatus'
import { mapState, mapActions } from 'vuex'
import moment from 'moment'
import DropdownCard from '@/components/elements/DropdownCard'
import CONFIG from '@root/config'

export default {
    name: 'OrderLineArtworkCheck',
    components: { ProductInfo, ArtworkFiles, PreviousArtwork, LogList, LogForm, DtpStatus, FollowUp, DropdownCard },
    props: ['isOverWarningValue', 'warningValue'],
    data () {
        return {
            isCheckInProgress: false,
            templateData: null,
        }
    },
    methods: {
        ...mapActions('customer', ['setActiveDtpAgent']),
        checkInProgress () {
            if (!this.orderLine.artworkTasks) {
                this.isCheckInProgress = false
                return
            }

            const now = moment().utc().unix()
            for (let key in this.orderLine.artworkTasks.CHECK) {
                if (this.orderLine.artworkTasks.CHECK[key].find(el => (moment(`${el.dateTo}+0000`).utc().unix() > now && el.accountId !== this.user.accountId))) {
                    const agent = this.orderLine.artworkTasks.CHECK[key].find(el => moment(`${el.dateTo}+0000`).utc().unix() > now)
                    this.setActiveDtpAgent(agent)
                    this.isCheckInProgress = true
                    return
                }
            }
            this.isCheckInProgress = false
            return false
        },
        fetchTemplateData () {
            const url = CONFIG.API.ROUTES.ARTWORKS.GET_TEMPLATES
            const payload = {
                sku: this.orderLine.product.sku,
                supplierId: this.orderLine.product.producer.supplierId,
                productHash: this.orderLine.product.productHash,
            }
            this.post(url, payload).then(({ data }) => {
                this.templateData = data.templates
            })
        },
    },
    computed: {
        ...mapState('auth', ['user']),
        ...mapState('order', ['orderLine']),
        ...mapState('customer', ['activeDtpAgent']),
        isAssigned () {
            if (this.$store.state.order.assignedOrder) {
                return true
            }
            return false
        },
        isUserOrder () {
            if (this.$store.state.order.assignedOrder.orderId === this.$route.params.orderId) {
                return true
            }
            return false
        },
    },
    created () {
        this.checkInProgress()
        this.fetchTemplateData()
    },
}
</script>

<style lang="scss" scoped>
    $red: #E00000;
    .checked-in-message {
        font-size: 20px;
        line-height: 48px;
        color: white;
        background-color: #333;
        margin: 0;
        text-align: center;
        text-transform: uppercase;
    }
    .value-note {
        color: white;
        font-weight: bold;
        text-align: center;
        padding: 12px;
        background: $red;
        margin: 10px 0 0;
        font-size: 18px;
    }
    .children-flex {
        height: 100%;
    }
    .lock-screen {
        display: flex;
        flex: 1 1 auto;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        i {
            font-size: 50px !important;
            margin-top: 15px;
        }

        h4 {
            font-weight: normal;
            font-size: 16px;
            margin-top: 20px;
            margin-bottom: 0;
        }

        h3 {
            margin-top: 5px;
            font-size: 20px;
            margin-bottom: 0;
        }
    }

    .max-height-500 {
        max-height: 500px;
    }
    .max-height-600 {
        max-height: 600px;
    }
</style>
