<template>
    <mercur-card class="c-dtp-status mb-3">
        <template class="c-dtp-status__wrapper" v-if="dtpOverview">
            <div class="c-dtp-status__item">
                <div class="c-dtp-status__item-note c-dtp-status__item-note--pending">{{ dtpOverview.pendingTasks }}</div>
                <p class="c-dtp-status__item-big">Pending tasks</p>
                <div class="c-dtp-status__item-note c-dtp-status__item-note--red">{{ dtpOverview.supplierSla.breach }}</div>
                <div class="c-dtp-status__item-note c-dtp-status__item-note--yellow">{{ dtpOverview.supplierSla.attention }}</div>
                <div class="c-dtp-status__item-note c-dtp-status__item-note--green">{{ dtpOverview.supplierSla.ok }}</div>
                <p>Supplier SLA</p>
            </div>
        </template>
    </mercur-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
    name: 'DtpStatus',
    methods: {
        ...mapActions('order', ['fetchDtpOverview']),
    },
    computed: {
        ...mapState('order', ['dtpOverview']),
    },
    created () {
        this.fetchDtpOverview()
    },
}
</script>

<style lang="scss" scoped>
    $red: #DA0724;
    $green: #77BE29;
    $yellow: #F1B818;
    $grey: #E2E2E2;

    .c-dtp-status {
        background: $grey;
        max-height: 58px;

        p {
            margin: 0;
        }

        &__item {
            display: flex;
            flex-wrap: nowrap;
            align-items: center;

            &-big {
                flex: 1;
            }
        }

        &__wrapper {
            display: flex;
            max-height: 120px;

            .c-dtp-status__item {
                flex: 1;
            }
        }

        &__item-note {
            color: white;
            padding: 2px 0px;
            background: $grey;
            display: inline-block;
            text-align: center;
            width: 36px;
            margin-right: 4px;

            &--red {
                background-color: $red;
            }

            &--green {
                background-color: $green;
            }

            &--yellow {
                background-color: $yellow;
            }

            &--pending {
                background: #979797;
                color: white;
            }
        }
    }
</style>
